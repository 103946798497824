import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "44",
  viewBox: "0 0 50 44",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F06F02",
      d: "M13.7904 36.7453C10.0273 34.8808 7.37835 31.5498 6.36653 27.6276L0 33.9941L7.58299 36.2451L9.83401 43.8281L15.905 37.7572L13.7904 36.734V36.7453Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      d: "M36.2096 36.7453C39.9727 34.8808 42.6216 31.5498 43.6335 27.6276L50 33.9941L42.417 36.2451L40.166 43.8281L34.095 37.7572L36.2096 36.734V36.7453Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M14.7112 34.8467L24.7271 39.6671L34.7431 34.8467C38.8358 32.8117 41.4279 28.628 41.4279 24.0578V5.08322L24.7271 0.17189L8.02637 5.08322V24.0578C8.02637 28.6394 10.6185 32.8117 14.7112 34.8467ZM22.9174 28.0536L35.6141 11.8058L32.4623 9.34288L22.5579 22.0174L16.8347 16.2587L13.9975 19.0783L22.9174 28.0536Z"
    }, null, -1)
  ])))
}
export default { render: render }